.services-section {
    padding: 5%;
    background-color: #fff;
}

.title {
    font-size: 1.7rem !important;
    font-weight: 700 !important;
    text-align: center !important;
    text-transform: uppercase !important;
    padding: 1% !important;
    /* color: #017D85 !important; */
    color: black !important;
    font-family: 'Conthrax' !important;
}
.title-sm {
    display: none !important;
}

.service-item {
    background-color: transparent;
    text-align: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 250px !important;
}
  
.service-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
}
  
.service-item img {
    width: 100%;
    height: 250px;
    object-fit: cover !important;
    transition: transform 0.3s ease;
}
  
.service-item:hover img {
    transform: scale(1.1);
}
  
.service-item .item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 1;
    padding: 16px;
    max-width: 80%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    font-size: 1.5rem !important;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Conthrax' !important;
}
.projects-section {
    padding: 5%;
    background-color: #00000015;
    text-align: center;
}

.grey-section {
    background-color: #00000015 !important;

}

.projects-description {
    font-family: 'Calibri' !important;
    font-size: 20px !important;
}

.grid-container {
    width: 100%;
    margin: auto !important;
    display: flex !important;
    justify-content: center !important;
}
  
.projects-button {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    background-color: #bda13e !important;
    font-family: 'Conthrax' !important;
}

.certificates-section {
    padding: 5%;
    justify-content: center;
    margin-top: 5% !important;
    /* margin-bottom: 5% !important; */
    /* padding-bottom: 5% !important; */
}
.certificate-item {
    background-color: transparent;
    text-align: center;
    position: relative;
    overflow: hidden;
    width: 200px;
    justify-content: center;
    margin: auto;
    padding: 5%;
}

.certificate-item img {
    height: 150px;
    object-fit: cover;
    transition: transform 0.3s ease;
}


.image-gallery {
    margin-top: 3rem !important;
}
  
.section-img {
    width: 100% !important;
    height: auto !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}


  
@media (max-width: 768px) {

    .image-gallery {
        flex-direction: column !important;
    }

    .section-img {
        width: 80% !important;
        margin: 0 auto !important;
    }
    .title-sm {
        display: block !important;
    }
    .title-lg {
        display: none !important;
    }
    .service-item .item-text {
        font-size: 1rem !important;
    }
}
  
@media (max-width: 480px) {
    .title {
        font-size: 1.2rem !important;
    }
    .title-sm {
        display: block !important;
    }
    .title-lg {
        display: none !important;
    }
    .service-item .item-text {
        font-size: 1rem !important;
    }
}