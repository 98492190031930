
.floating-button {
    position: fixed !important;
    bottom: 20px !important;
    right: 20px !important;
    z-index: 1000 !important;
    background-color: #bda13e !important;
    padding: 2.4% !important;
}
.floating-button:hover {
    background-color: #a38d3b !important;

}

@media (max-width: 600px) {
    .floating-button {
        bottom: 15px !important;
        right: 15px !important;
    }
}
