/* General styles for the slider */
.slider-container-fullscreen {
  height: 100vh !important;
  width: 100% !important;
  position: relative !important;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
}

.slider-container {
  height: 80vh !important;
  width: 100% !important;
  position: relative !important;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
}

.slide {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  transition: opacity 0.5s ease !important;
  opacity: 0 !important;
}

.slide.active {
  opacity: 1 !important;
}

.slide-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.slogan-container {
  position: absolute !important;
  top: 25% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 10 !important;
  border-radius: 10px !important;
}

.slogan-image {
  max-width: 900px !important;
  height: auto !important;
}

.slide-text {
  font-family: 'Conthrax' !important;
  text-transform: uppercase !important;
  position: absolute !important;
  bottom: 30% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  color: white !important;
  font-size: 2.1rem !important;
  font-weight: bold !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.203), -3px -3px 8px rgba(0, 0, 0, 0.24) !important;
  background: rgba(0, 0, 0, 0.4) !important;
}

.arrow {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  cursor: pointer !important;
  width: 50px !important;
  height: 50px !important;
  background-color: rgba(255, 255, 255, 0.225) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
}

.arrow:hover {
  background-color: rgba(255, 255, 255, 0.477) !important;
}

.arrow.left {
  left: 10px !important;
}

.arrow.right {
  right: 10px !important;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .slogan-container {
    top: 50vh !important;
  }

  .slogan-image {
    max-width: 100% !important;
  }

  .slide-text {
    font-size: 30px !important;
    bottom: 20% !important;
    padding: 5px 10px !important;
  }

  .arrow {
    width: 40px !important;
    height: 40px !important;
  }
}

@media (max-width: 480px) {
  .slider-container,
  .slider-container-fullscreen,
  .slide,
  .slide-image {
    width: 100% !important;
  }

  .slogan-container {
    top: 50vh !important;
    height: auto !important;
  }

  .slogan-image {
    max-width: 100% !important;
    transform: scale(2) !important;
  }

  .slide-text {
    font-size: .9rem !important;
    bottom: 10% !important;
    padding: 5px 10px !important;
    white-space: nowrap !important; /* Prevent text from wrapping */
    overflow: hidden !important; /* Hide overflow text */
    text-overflow: ellipsis !important; /* Add ellipsis if text overflows */
  }

  .arrow {
    width: 30px !important;
    height: 30px !important;
  }
}
