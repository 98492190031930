/* clientsSlider.css */
.clients-slider {
  position: relative !important;
  width: 100% !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
  overflow: hidden !important;
}

.clients-slide-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 10px !important; /* Optional: add some padding */
}

.clients-slide {
  width: 180px !important; /* Fixed width */
  height: 200px !important; /* Fixed height */
  object-fit: cover !important; /* Ensures the image covers the box */
  border-radius: 8px !important; /* Optional: add border radius */
}

.clients-arrow {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  background-color: rgba(0, 0, 0, 0.15) !important;
  color: white !important;
  border: none !important;
  padding: 10px !important;
  cursor: pointer !important;
  z-index: 2 !important;
}

.clients-left-arrow {
  left: 10px !important;
}

.clients-right-arrow {
  right: 10px !important;
}

.clients-arrow:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
