/* Calibri */
@import url('https://fonts.googleapis.com/css2?family=Calibri:wght@400;700&display=swap');

/* Conthrax-sb */
@font-face {
    font-family: 'Conthrax';
    src: url('../assets/fonts/conthrax-sb.otf') format('woff2'),
         url('../assets/fonts/conthrax-sb.otf') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Apply Calibri to all common text */
body {
    font-family: 'Calibri', sans-serif;
}

/* Apply the special font to specific elements like headers */
h1, h2, h3, h4, h5, h6, p, span, div {
    font-family: 'Calibri', sans-serif !important;
}
