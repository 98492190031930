/* Footer CSS */

.footer-container {
    background-color: #292929 !important;
    color: white !important;
    padding: 10px 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    margin-top: auto !important;
  }
  
  .footer-content {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
    padding: 0 20px !important;
    max-width: 1200px !important; /* To limit the width on larger screens */
    flex-wrap: wrap !important; /* Allow content to wrap on smaller screens */
  }
  
  .company-name {
    font-family: 'Conthrax' !important;
    flex: 1 !important;
    text-align: left !important; /* Align company name to the left */
    order: 1 !important;
  }
  
  .links {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 20px !important;
    flex: 2 !important;
    order: 2 !important;
  }
  
  .footer-link {
    font-family: 'Conthrax' !important;
    cursor: pointer !important;
    transition: color 0.3s ease !important;
  }
  
  .footer-link:hover {
    color: #bda13e !important;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .footer-container {
        margin-top: 20% !important;
    }
    .footer-content {
      flex-direction: column !important;
      text-align: center !important;
    }
  
    .company-name {
      text-align: center !important;
      margin-bottom: 10px !important;
    }
  
    .links {
      justify-content: center !important;
      flex-direction: column !important;
      gap: 10px !important;
    }
    .footer-link-hidden {
        display: none !important;
    }
  }
  
  @media (max-width: 480px) {
    .footer-container {
        margin-top: 20% !important;
    }
    .footer-content {
      padding: 10px !important;
    }
  
    .footer-link {
      font-size: 14px !important;
    }
  
    .company-name {
      font-size: 14px !important;
    }
    .footer-link-hidden {
        display: none !important;
    }
  }
  