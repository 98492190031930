body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
}

.container {
  max-width: 960px;
  margin: auto;
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
