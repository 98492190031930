.header {
  background-color: #bda13e !important;
  color: black !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  height: 110px !important;
  justify-content: center !important;
}

.header::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -3px; 
  left: 0;
  width: 100%;
  height: 3px; 
  background: linear-gradient(to bottom, rgba(189, 161, 62, 0.5), rgba(189, 161, 62, 0));
  transition: opacity 0.3s ease;
}

.header.scrolled::after {
  opacity: 0;
}

.toolbar {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.menu-icon {
  display: none !important;
}

.nav-links {
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
  margin-right: 15px !important;
  margin-top: 4% !important;
}

.logo {
  width: 100px !important;
  padding: 20px !important;
  width: 135px !important;
  flex-shrink: 0 !important;
}

.navlink {
  color: black!important;
  text-decoration: none!important;
  position: relative!important;
  transition: color 0.3s ease!important;
  margin: 16px!important;
  white-space: nowrap;
  font-family: 'Conthrax' , 'sans-serif' !important;
  text-transform: uppercase !important;
  font-size: .9rem !important;
}

.navlink.active {
  color: white !important;
}

.navitem-with-dropdown {
  position: relative;
}

.navitem-with-dropdown .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgb(41, 41, 41);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px;
  border-radius: 8px;
  font-family: 'Conthrax' , 'sans-serif' !important;
  font-size: 1rem !important;
}

.navitem-with-dropdown:hover > .dropdown,
.navitem-with-dropdown:hover > .nested-dropdown {
  display: block;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
}

.dropdown-navlink {
  font-family: 'Conthrax' !important;
  text-transform: uppercase !important;
  font-size: .8rem !important;
  padding: 12px 16px;
  text-decoration: none;
  color: rgb(185, 159, 36);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.dropdown-navlink:hover {
  background-color: #bda13e;
  color: white;
  border-radius: 8px;
}

.nested-dropdown {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 1px;
  background-color: rgb(41, 41, 41);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  min-width: 160px;
  border-radius: 8px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .nav-links {
    display: none !important;
  }

  .menu-icon {
    display: flex !important;
  }
}

@media (max-width: 480px) {
  .header {
    height: 80px !important;
  }

  .logo {
    width: 80px !important;
  }

  .navlink {
    font-size: 0.8rem !important;
    margin: 12px !important;
  }

  .dropdown-navlink {
    font-size: 0.7rem !important;
    padding: 10px 14px !important;
  }

  .toolbar {
    padding: 0 10px !important;
  }
}

.drawer-content {
  width: 250px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #bda13e !important;
  color: black !important;
  height: 100%;
}

.drawer-content .navlink {
  margin: 8px 0 !important;
  font-size: 1rem !important;
  color: black !important;
}

.drawer-content .navlink:hover {
  color: white !important;
}

.drawer-content .navitem-with-dropdown .dropdown {
  position: static;
  box-shadow: none;
}

.drawer-content .dropdown-content {
  flex-direction: column;
}
