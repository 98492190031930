.page-container {
    position: relative;
    height: 102vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container {
    text-align: center;
}

.auth-logo-container img {
    max-width: 100%;
    height: auto;
}

.spinner-container {
    margin-top: 20px; /* Add some spacing between the logo and spinner */
}

.spinner-border {
    width: 3rem;
    height: 3rem;
    border: 0.25rem solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
    to { transform: rotate(360deg); }
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
